@use './functions' as *;
@use './generator-mixin' as *;

//  Generating shadows
$shadow-variants: (
	'100': (
		0px 3px 6px rgba(9, 30, 66, 0.03),
		0px 2px 4px rgba(9, 30, 66, 0.08),
	),
	'200': (
		0px 2px 6px rgba(9, 30, 66, 0.04),
		0px 8px 12px rgba(9, 30, 66, 0.08),
	),
	'300': (
		0px 2px 8px rgba(9, 30, 66, 0.04),
		0px 12px 32px rgba(9, 30, 66, 0.12),
	),
	'400': (
		0px 20px 40px rgba(9, 30, 66, 0.12),
	),
);

/* Generating Shadow css variables for all the variants
 * This is going to generate shadow variable for all variants defined above
 * One of the variable generated is shown below:
 * --shadow-400: 0px 20px 40px rgba(9, 30, 66, 0.12);
 */
:root {
	@include variableGenerator('shadow', $shadow-variants);
}

/* Generating Font CSS classes for all the sizes
 * This is going to generate shadow classes for all variants defined above
 * One of the class generated is shown below:
 * shadow-400 {
 *   box-shadow: 0px 20px 40px rgba(9, 30, 66, 0.12);
 * }
 */
@include classGenerator('box-shadow', $shadow-variants, 'shadow');
