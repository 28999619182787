// Font Families
// Taken the files from ares-platform
@font-face {
	font-family: 'CircularXX Regular';
	src: url('../../fonts/CircularXX/CircularXXWeb-Regular.woff2') format('woff2'),
		url('../../fonts/CircularXX/CircularXXWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'CircularXX Medium';
	src: url('../../fonts/CircularXX/CircularXXWeb-Medium.woff2') format('woff2'),
		url('../../fonts/CircularXX/CircularXXWeb-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'CircularXX Book';
	src: url('../../fonts/CircularXX/CircularXXWeb-Book.woff2') format('woff2'),
		url('../../fonts/CircularXX/CircularXXWeb-Book.woff') format('woff');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

// Fonts
:root {
	--font-regular: 'CircularXX Regular', sans-serif;
	--font-medium: 'CircularXX Medium', sans-serif;
	--font-book: 'CircularXX Book', sans-serif;
}

// TODO: add FFMark Bold file and move below line inside root above
//   --font-ffmark-bold: 'FFMark Bold', sans-serif;
