@use 'styles/functions' as *;

// .ant-input,
// .ant-input-number {
// 	border-radius: 8px;
// }

.ant-tabs-tab:hover {
	background-color: #f0f0f0;
}
.ant-tabs-nav {
	background-color: #fff;
	color: #4a4a4a;
	font-weight: bold;
}
// Filter
.filter-card-header {
	.ant-card-head-title,
	.ant-card-extra {
		padding-top: 12px;
		padding-bottom: 12px;
	}
}
.collapse-container {
	.ant-collapse-header {
		padding: 16px 48px 16px 20px !important;
	}
}
.filters-container {
	& * {
		color: var(--grey1) !important;
	}
	.ant-collapse-icon-position-right
		> .ant-collapse-item
		> .ant-collapse-header
		.ant-collapse-arrow {
		right: 20px;
	}
	> .collapse-container {
		> .ant-switch {
			min-width: 32px;
			height: 18px;
			> .ant-switch-handle {
				top: 3px;
				left: 3px;
				width: 12px;
				height: 12px;
			}
			&.ant-switch-checked {
				> .ant-switch-handle {
					left: calc(100% - 18px + 3px);
				}
			}
		}
		> .ant-collapse-item
		> .ant-collapse-content
		> .ant-collapse-content-box {
			padding: 10px 20px 16px 20px;
			> .ant-collapse:first-child {
				> .ant-collapse-item 
				> .ant-collapse-header {
					padding-top: 0 !important;
					> div > .ant-collapse-arrow {
						top: 30%;
					}
				}
			}
			> .ant-collapse {
				> .ant-collapse-item 
				> .ant-collapse-content
				> .ant-collapse-content-box {
					padding-top: 0 !important;
				}
			}
			
			> .ant-space {
				width: 100%;
				max-height: 150px;
				overflow-y: auto;
				&::-webkit-scrollbar {
					width: 4px;
					padding: 1px;
				}
			
				/* Track */
				&::-webkit-scrollbar-track {
					width: 2px;
					background-color: var(--grey7);
					border-radius: 2px;
				}
			
				/* Handle */
				&::-webkit-scrollbar-thumb {
					background-color: var(--grey5);
					border: 1px solid transparent;
					border-radius: 2px;
					background-clip: content-box;
				}
				> .ant-space-item
				> .ant-checkbox-group {
					display: flex;
					flex-direction: column;
					gap: 5px;
					> .ant-checkbox-wrapper 
					> .ant-checkbox:not(.ant-checkbox-checked)
					> .ant-checkbox-inner {
						background-color: transparent;
					}
				}
			}
		}
	}
}

// SEARCH-BAR ANT override
.ant-select-multiple {
	.ant-select-selector {
		@include screen('mobile') {
			border: 1px solid black;
		}
	}
	.ant-select-selection-placeholder {
		@include screen('mobile') {
			color: var(--black3);
			font-size: 14px;
		}
		@include screen('tablet-only') {
			color: var(--primary-dark-blue);
			font-size: 14px;
		}
		@include screen('desktop') {
			left: 44px;
		}
	}
}
.ant-select-selection-overflow {
	margin-left: 8px;
	margin-right: -8px;
	.ant-select-selection-overflow-item:first-of-type {
		&.ant-select-selection-overflow-item-suffix {
			margin-left: -8px;
		}
	}
}
// BUTTON LINK ANT oveeride
.ant-btn-link {
	color: #3679fb;
}
// PROFILE DROPDOWN
.profile-dropdown {
	.ant-popover-arrow-content {
		background-color: var(--black2);
	}
}

.change-password-form {
	.ant-form-item-explain-error {
		font-size: var(--font-12);
	}
}

//profile-fit
.ant-form-item-explain-error {
	@include screen('mobile') {
		font-size: rem(12px);
	}
}
// Jobs Count chip
.jobsCountChipModal {
	.ant-modal-content {
		border-radius: 10px;
	}
}

.drawerContainerApplicationCap {
	.ant-drawer-content {
		border-radius: 10px 10px 0 0;
	}
	.ant-drawer-header-title {
		justify-content: flex-end;
		.ant-drawer-close {
			margin-right: 0;
		}
	}
}

.tooltipApplyButton {
	.ant-tooltip-arrow {
		width: 13.071068px;
		height: 17.071068px;
		.ant-tooltip-arrow-content {
			width: 12px;
			height: 12px;
		}
	}
}
.application-cap-tooltip {
	.ant-tooltip-arrow {
		width: 13.071068px;
		height: 17.071068px;
		.ant-tooltip-arrow-content {
			box-shadow: none;
			width: 11px;
			height: 12px;
			border-left: 1px solid var(--secondary-light-blue-5);
			border-top: 1px solid var(--secondary-light-blue-5);
		}
	}
}
.introductionTooltip {
	.ant-tooltip-arrow {
		width: 13.071068px;
		height: 17.071068px;
		.ant-tooltip-arrow-content {
			box-shadow: none;
			width: rem(11px);
			height: rem(12px);
			border-left: rem(1px) solid var(--secondary-light-blue-5);
			border-top: rem(1px) solid var(--secondary-light-blue-5);
		}
	}
}
