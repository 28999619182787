@use 'functions';
@use 'shadow-generator';
@use 'radius-generator';
@use 'space-generator';
@use 'typography/index';
@use 'color';
@use 'mixins';
@import 'responsive';
@import 'ant-override';

// TODO: check if normalize is needed after adding ant design
// @import-normalize;

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
	font-feature-settings: 'tnum' 0;
}

body {
	margin: 0;

	font-family: var(--font-regular);
	// font-feature-settings: "tnum" off;
	// word-spacing: -5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.height-100 {
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

// GENERAL CLASSES
.visible {
	visibility: visible;
}
.hidden {
	visibility: hidden;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// Font Family
.font-medium {
	font-family: var(--font-medium);
}

.font-book {
	font-family: var(--font-book);
}

.font-regular {
	font-family: var(--font-regular);
}

.display-none {
	display: none !important;
}
