// Transformation
.text-lowercase {
	text-transform: lowercase;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-capitalize {
	text-transform: capitalize;
}

// Alignment
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-justify {
	text-align: justify;
}

// Whitespace
.text-wrap {
	white-space: normal;
}
.text-nowrap {
	white-space: nowrap;
}

// Word Break
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text-break-words {
	overflow-wrap: break-word;
}
.text-break-all {
	word-break: break-all;
}
