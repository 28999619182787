// TODO: change font-family to FFMark Bold for all headings
.heading1 {
	// font-family: var(--font-eina-regular);
	font-size: var(--font-72);
	line-height: 120%;
	letter-spacing: 0;
}
.heading2 {
	// font-family: var(--font-eina-regular);
	font-size: var(--font-48);
	line-height: 120%;
	letter-spacing: 0;
}
.heading3 {
	// font-family: var(--font-eina-regular);
	font-size: var(--font-28);
	line-height: 130%;
	letter-spacing: 0;
}
.heading4 {
	// font-family: var(--font-eina-regular);
	font-size: var(--font-24);
	line-height: 130%;
	letter-spacing: 0;
}
.body1 {
	font-family: var(--font-eina-regular);
	font-size: var(--font-22);
	line-height: 150%;
	letter-spacing: 0;
}
.body2 {
	font-family: var(--font-eina-regular);
	font-size: var(--font-20);
	line-height: 150%;
	letter-spacing: 0;
}
.body2 {
	font-family: var(--font-eina-regular);
	font-size: var(--font-18);
	line-height: 150%;
	letter-spacing: 0;
}
.action {
	font-family: var(--font-eina-semibold);
	font-size: var(--font-18);
	line-height: 120%;
	letter-spacing: 0;
}
.caption-overline {
	font-family: var(--font-eina-semibold);
	font-size: var(--font-16);
	line-height: 120%;
	letter-spacing: 1px;
}
.caption-helper {
	font-family: var(--font-eina-semibold);
	font-size: var(--font-14);
	line-height: 120%;
	letter-spacing: 0;
}
