@use 'styles/functions' as *;
@use 'styles/responsive' as *;

.container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background: var(--black1);
}
