/*
 * @description it generates the css variables
 *
 * @param property {string} - name given to the property
 * @param map {scss map} - css map function which contains the values 
 */
@mixin variableGenerator($property, $map) {
	// Generating CSS variables
	@each $prefix, $value in $map {
		--#{$property}-#{$prefix}: #{$value};
	}
}

/*
   * @description it generates the css classes
   *
   * @param property {string} - css property which is to be generated
   * @param map {scss map} - css map function which contains the values
   * @param alias {string} (optional) - alias to the css
   */
@mixin classGenerator($property, $map, $alias: '') {
	$alias: if($alias == '', $property, $alias);
	// Generating CSS classes
	@each $prefix, $value in $map {
		.#{$alias}-#{$prefix} {
			#{$property}: #{$value};
		}
	}
}
