// https://w3bits.com/sass-mixins/

// Media queries for different screen resolutions
/// @example scss
/// .wrapper {
///     margin: 0 auto;
///     width: 100%;
///     @include screen('tablet') {
///       width: 90%;
///     }
///     @include screen('desktop') {
///       width: 85%;
///     }
///   }
///
/// @example scss complies to
/// .wrapper {
///     margin: 0 auto;
///     width: 100%;
///   }
///   @media only screen and (min-width: 768px) and (max-width: 1023px) {
///     .wrapper {
///       width: 90%;
///     }
///   }
///   @media only screen and (min-width: 1024px) {
///     .wrapper {
///       width: 85%;
///     }
///   }

$tablet-width: 768px;
$desktop-width: 992px;
$desktop: '(min-width: #{$desktop-width})';
$tablet: '(min-width: #{$tablet-width})';
$tablet-only: '(min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1})';
$mobile: '(max-width: #{$tablet-width - 1})';

@mixin screen($size) {
	@if $size == desktop {
		@media only screen and #{$desktop} {
			@content;
		}
	} @else if $size == tablet {
		@media only screen and #{$tablet} {
			@content;
		}
	} @else if $size == tablet-only {
		@media only screen and #{$tablet-only} {
			@content;
		}
	} @else if $size == mobile {
		@media only screen and #{$mobile} {
			@content;
		}
	} @else {
		@media only screen and #{$size} {
			@content;
		}
	}
}
