$spaceamounts: (0, 4, 8, 10, 12, 16, 20, 24, 32, 40, 48, 64, 80, 120);
$sides: (
	'': 'all',
	't': 'top',
	'b': 'bottom',
	'l': 'left',
	'r': 'right',
);

/* 
 * This is going to generate paddings and margins classes 
 * for the spaces and sides defined above, the generated css is:
 * for space 0 below classes are generated
 * margins => .m-0, .mt-0, .mb-0, .ml-0, .mr-0 
 * padding => .p-0, .pt-0, .pb-0, .pl-0, .pr-0 
 */
@each $space in $spaceamounts {
	@each $prefix, $value in $sides {
		$property: if($prefix == '', '', -#{$value});
		.m#{$prefix}-#{$space} {
			margin#{$property}: #{$space}px !important;
		}
		.p#{$prefix}-#{$space} {
			padding#{$property}: #{$space}px !important;
		}
	}
}
