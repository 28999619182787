@use 'functions' as *;
@use './generator-mixin' as *;

//  Generating Font sizes
$radius-sizes: (
	'0': 0px,
	'2': rem(2px),
	'4': rem(4px),
	'6': rem(6px),
	'8': rem(8px),
	'12': rem(12px),
	'circle': 50%,
);

/* Generating radius css variables for all the sizes
 * This is going to generate radius variable for all sizes defined above
 * One of the variable generated is shown below:
 * --radius-2: rem(2px);
 */
:root {
	@include variableGenerator('radius', $radius-sizes);
}

/* Generating Radius CSS classes for all the sizes
 * This is going to generate Radius classes for all sizes defined above
 * One of the class generated is shown below:
 * radius-2 {
 *   radius: rem(2px);
 * }
 */
@include classGenerator('border-radius', $radius-sizes, 'radius');
